define("discourse/plugins/discourse-custom-ap-profile/discourse/templates/connectors/user-card-additional-buttons/profile", ["exports", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _thisFallbackHelper, _tryLookupHelper, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if isCAPPAvailable}}
    <a href="{{user.path}}" class="btn btn-default" style="width: 100%">{{d-icon "user"}}Se profil</a>
  {{/if}}
  
  */
  {
    "id": "BKkPnLmb",
    "block": "[[[41,[30,0,[\"isCAPPAvailable\"]],[[[1,\"  \"],[44,[[28,[37,2],null,[[\"user\"],[[28,[32,0],[\"user\"],null]]]]],[[[10,3],[15,6,[29,[[52,[30,1,[\"user\"]],[28,[30,1,[\"user\"]],null,null],[28,[32,1],[[30,0],\"user.path\",\"[\\\"The `user` property path was used in the `discourse/plugins/discourse-custom-ap-profile/discourse/templates/connectors/user-card-additional-buttons/profile.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.user}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]]],[14,0,\"btn btn-default\"],[14,5,\"width: 100%\"],[12],[1,[28,[35,4],[\"user\"],null]],[1,\"Se profil\"],[13]],[1]]],[1,\"\\n\"]],[]],null],[1,[28,[32,2],[\"[[\\\"The `isCAPPAvailable` property path was used in the `discourse/plugins/discourse-custom-ap-profile/discourse/templates/connectors/user-card-additional-buttons/profile.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.isCAPPAvailable}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"maybeHelpers\"],false,[\"if\",\"let\",\"hash\",\"a\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-custom-ap-profile/discourse/templates/connectors/user-card-additional-buttons/profile.hbs",
    "scope": () => [_tryLookupHelper.default, _thisFallbackHelper.default, _deprecationsHelper.default],
    "isStrictMode": false
  });
});